import { database } from ".";
import { ref, set } from "firebase/database";

export const updateDocumentDate = async (path: string) => {
  const docRef = ref(database, `updateDates/${path}`);
  const date = new Date();
  await set(docRef, {
    date: date.toISOString(),
  });
};
