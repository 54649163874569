import { db } from ".";
import {
  collection,
  getDocs,
  addDoc,
  doc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { sanitizeFirebaseData } from "../utils/misc.utils";

export const getCollection = async (collectionName: string) => {
  try {
    console.log("Fetching data from Firebase");
    const querySnapshot = await getDocs(collection(db, collectionName));
    const collectionData = querySnapshot.docs.map((doc) => {
      return { ...sanitizeFirebaseData(doc.data()), id: doc.id };
    });
    return collectionData;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const addDocument = async (collectionName: string, data: any) => {
  try {
    const docRef = await addDoc(collection(db, collectionName), data);
    return docRef;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const deleteDocument = async (collectionName: string, docId: string) => {
  await deleteDoc(doc(db, collectionName, docId));
};

export const updateDocument = async (
  collectionName: string,
  docId: string,
  data: any
) => {
  await updateDoc(doc(db, collectionName, docId), data);
};
