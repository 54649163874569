import React, { FC, useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  useTheme,
  Collapse,
  Button,
  MenuItem,
  Grid,
  CircularProgress,
  Switch,
} from "@mui/material";
import NavLayout from "../Layout/NavLayout";
import {
  getCollection,
  addDocument,
  updateDocument,
  deleteDocument,
} from "../Firebase/firestore";
import { updateDocumentDate } from "../Firebase/db";
import { toast } from "react-toastify";
import { object, string, number, array, boolean } from "yup";
import {
  Add,
  Close,
  Delete,
  Done,
  Edit,
  Remove,
  Search,
} from "@mui/icons-material";
import { IProjectData } from "../Interface/data.interface";

const Card: FC<{
  data: Partial<IProjectData>;
}> = ({ data }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState<Partial<IProjectData>>({
    title: "",
    short_description: "",
    description: "",
    image: "",
    github_link: "",
    demo_link: "",
    technologies: [],
    components: [],
    current_status: "",
    progress: 0,
    created_at: "",
    updated_at: "",
    is_open: false,
    is_featured: false,
    featured_image: "",
  });

  const handleEdit = () => {
    setIsEditing(true);
    setEditData(data);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditData({
      title: "",
      short_description: "",
      description: "",
      image: "",
      github_link: "",
      demo_link: "",
      technologies: [],
      components: [],
      current_status: "",
      progress: 0,
      created_at: "",
      updated_at: "",
      is_open: false,
      is_featured: false,
      featured_image: "",
    });
  };

  const handleUpdate = async () => {
    const updateData = {
      ...editData,
      updated_at: new Date().toISOString(),
    };
    const schema = object({
      title: string().required(),
      short_description: string().required(),
      description: string(),
      image: string(),
      github_link: string(),
      demo_link: string(),
      technologies: array().of(string()),
      components: array().of(string()),
      current_status: string(),
      progress: number(),
      created_at: string().required(),
      updated_at: string().required(),
      is_open: boolean(),
      is_featured: boolean().required(),
      featured_image: string(),
    });

    schema
      .validate(updateData)
      .then((res) => {
        updateDocument("projects", data.id!, updateData)
          .then(() => {
            toast.success("Project updated successfully");
            updateDocumentDate("projects")
              .then(() => {
                console.log("Updated");
              })
              .catch((err) => {
                console.log(err);
              });
            setIsEditing(false);
          })
          .catch((err) => {
            throw err;
          });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };

  const handleDelete = () => {
    deleteDocument("projects", data.id!)
      .then(() => {
        toast.success("Project deleted successfully");
        updateDocumentDate("projects")
          .then(() => {
            console.log("Updated");
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };

  return (
    <Box
      sx={{
        width: "100%",
        py: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        p: 2,
        borderRadius: 2,
        color: "backgroundColor.contrastText",
        backgroundColor: "backgroundColor.light",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <TextField
            color="accent"
            label="Title"
            variant="outlined"
            fullWidth
            value={isEditing ? editData.title : data.title}
            onChange={(e) => {
              setEditData({ ...editData, title: e.target.value });
            }}
            disabled={!isEditing}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            color="accent"
            label="Components"
            variant="outlined"
            fullWidth
            value={
              isEditing
                ? editData.components?.join(", ")
                : data.components?.join(", ")
            }
            disabled={!isEditing}
            onChange={(e) => {
              setEditData({
                ...editData,
                components: e.target.value.split(", "),
              });
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <Typography variant="caption">Open Project?</Typography>

            <Switch
              color="accent"
              checked={isEditing ? editData.is_open : data.is_open}
              onChange={(e) => {
                setEditData({ ...editData, is_open: e.target.checked });
              }}
              disabled={!isEditing}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={1}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 1,
          }}
        >
          {isEditing ? (
            <>
              <IconButton onClick={handleCancel}>
                <Close />
              </IconButton>
              <IconButton onClick={handleUpdate}>
                <Done />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton onClick={handleEdit}>
                <Edit />
              </IconButton>
              <IconButton onClick={handleDelete}>
                <Delete
                  sx={{
                    color: "error.main",
                  }}
                />
              </IconButton>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            color="accent"
            label="Short Description"
            variant="outlined"
            fullWidth
            value={
              isEditing ? editData.short_description : data.short_description
            }
            disabled={!isEditing}
            onChange={(e) => {
              setEditData({ ...editData, short_description: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            color="accent"
            label="Description"
            variant="outlined"
            fullWidth
            onChange={(e) => {
              setEditData({ ...editData, description: e.target.value });
            }}
            value={isEditing ? editData.description : data.description}
            multiline
            minRows={4}
            maxRows={isEditing ? 6 : 2}
            disabled={!isEditing}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            color="accent"
            label="Technologies"
            variant="outlined"
            fullWidth
            value={
              isEditing
                ? editData.technologies?.join(", ")
                : data.technologies?.join(", ")
            }
            disabled={!isEditing}
            onChange={(e) => {
              setEditData({
                ...editData,
                technologies: e.target.value.split(", "),
              });
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            color="accent"
            label="Image"
            variant="outlined"
            fullWidth
            value={isEditing ? editData.image : data.image || "No Image"}
            disabled={!isEditing}
            onChange={(e) => {
              setEditData({ ...editData, image: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            color="accent"
            label="Current Status"
            variant="outlined"
            fullWidth
            select
            disabled={!isEditing}
            value={isEditing ? editData.current_status : data.current_status}
            onChange={(e) => {
              if (e.target.value === "Completed") {
                setEditData({
                  ...editData,
                  progress: 100,
                  current_status: e.target.value,
                });
              } else if (e.target.value === "Not Started") {
                setEditData({
                  ...editData,
                  progress: 0,
                  current_status: e.target.value,
                });
              } else {
                setEditData({ ...editData, current_status: e.target.value });
              }
            }}
          >
            <MenuItem value="Not Started">Not Started</MenuItem>
            <MenuItem value="In Progress">In Progress</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
            <MenuItem value="On Hold">On Hold</MenuItem>
            <MenuItem value="Discarded">Discarded</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={3}>
          <TextField
            color="accent"
            label="Progress"
            variant="outlined"
            fullWidth
            onChange={(e) => {
              setEditData({
                ...editData,
                progress: parseInt(e.target.value) || 0,
              });
            }}
            value={
              isEditing ? editData.progress : data.progress || "No Progress"
            }
            disabled={!isEditing}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{
                    p: 1,
                  }}
                >
                  <CircularProgress
                    variant="determinate"
                    value={isEditing ? editData.progress : data.progress || 0}
                    sx={{
                      color: "success.main",
                    }}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {isEditing && (
                    <>
                      <IconButton
                        onClick={() => {
                          setEditData({
                            ...editData,
                            progress: editData?.progress
                              ? editData.progress - 1
                              : 0,
                          });
                        }}
                      >
                        <Remove />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setEditData({
                            ...editData,
                            progress: editData?.progress
                              ? editData.progress + 1
                              : 0,
                          });
                        }}
                      >
                        <Add />
                      </IconButton>
                    </>
                  )}
                </>
              ),
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            color="accent"
            label="Github Link"
            variant="outlined"
            fullWidth
            value={
              isEditing
                ? editData.github_link
                : data.github_link || "No Link Text"
            }
            disabled={!isEditing}
            onChange={(e) => {
              setEditData({ ...editData, github_link: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            color="accent"
            label="Demo Link"
            variant="outlined"
            fullWidth
            value={isEditing ? editData.demo_link : data.demo_link || "No Link"}
            disabled={!isEditing}
            onChange={(e) => {
              setEditData({ ...editData, demo_link: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="caption">Featured?</Typography>
            <Switch
              color="accent"
              checked={isEditing ? editData.is_featured : data.is_featured}
              onChange={(e) => {
                setEditData({ ...editData, is_featured: e.target.checked });
              }}
              disabled={!isEditing}
            />
          </Box>
        </Grid>
        <Grid item xs={10}>
          <TextField
            color="accent"
            label="Featured Image"
            variant="outlined"
            fullWidth
            value={
              (isEditing ? editData.featured_image : data.featured_image) ||
              "No Image"
            }
            disabled={!isEditing || !editData.is_featured}
            onChange={(e) => {
              setEditData({ ...editData, featured_image: e.target.value });
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const Projects = () => {
  const theme = useTheme();
  const [projects, setProjects] = useState<Partial<IProjectData>[]>([]);
  const [newProject, setNewProject] = useState<Partial<IProjectData>>({
    title: "",
    short_description: "",
    description: "",
    image: "",
    github_link: "",
    demo_link: "",
    technologies: [],
    components: [],
    current_status: "",
    progress: 0,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    is_open: false,
    is_featured: false,
    featured_image: "",
  });
  const [isAdding, setIsAdding] = useState(false);
  const [filteredData, setFilteredData] = useState<Partial<IProjectData>[]>([]);
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  const handleSubmit = () => {
    const schema = object({
      title: string().required(),
      short_description: string().required(),
      description: string(),
      image: string(),
      github_link: string(),
      demo_link: string(),
      technologies: array().of(string()),
      components: array().of(string()),
      current_status: string(),
      progress: number(),
      created_at: string().required(),
      updated_at: string().required(),
      is_open: boolean(),
      is_featured: boolean().required(),
      featured_image: string(),
    });

    schema
      .validate(newProject)
      .then((res) => {
        addDocument("projects", newProject)
          .then(() => {
            toast.success("Project added successfully");
            updateDocumentDate("projects");
            setIsAdding(false);
          })
          .catch((err) => {
            throw err;
          });
      })
      .catch((err: any) => {
        console.log(err);
        toast.error(err.message);
      });
  };

  const handleSearch = () => {
    if (search.length > 0) {
      setIsSearching(true);
      setFilteredData(
        projects.filter((project) =>
          project.title?.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setIsSearching(false);
    }
  };

  useEffect(() => {
    getCollection("projects")
      .then((res) => {
        console.log(res);
        setProjects(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <NavLayout>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "calc(100% - 1rem)",
            height: isAdding ? "fit-content" : "11rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 2,
            p: 2,
            borderRadius: 2,
            backgroundColor: `${theme.palette.backgroundColor.light}dd`,
            backdropFilter: "blur(10px)",
            position: "absolute",
            top: "0.5rem",
            left: "0rem",
            zIndex: 99,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: "accent.main",
            }}
          >
            Projects
          </Typography>
          <TextField
            label="Search"
            variant="outlined"
            fullWidth
            value={search}
            sx={{
              "& input": {
                color: "accent.main",
              },
            }}
            color="accent"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSearch}>
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: isAdding ? 2 : 0,
            }}
          >
            <Collapse
              unmountOnExit
              in={isAdding}
              sx={{
                "& .MuiCollapse-wrapperInner": {
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  backgroundColor: "backgroundColor.light",
                  borderRadius: 2,
                  p: isAdding ? 1 : 0,
                },
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    color="accent"
                    label="Title"
                    variant="outlined"
                    fullWidth
                    value={newProject.title}
                    onChange={(e) => {
                      setNewProject({ ...newProject, title: e.target.value });
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    color="accent"
                    label="Components"
                    variant="outlined"
                    fullWidth
                    value={newProject.components?.join(", ") || ""}
                    onChange={(e) => {
                      setNewProject({
                        ...newProject,
                        components: e.target.value.split(", "),
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: "backgroundColor.contrastText",
                      }}
                    >
                      Open Project?
                    </Typography>

                    <Switch
                      color="accent"
                      checked={newProject.is_open || false}
                      onChange={(e) => {
                        setNewProject({
                          ...newProject,
                          is_open: e.target.checked,
                        });
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    color="accent"
                    label="Short Description"
                    variant="outlined"
                    fullWidth
                    value={newProject.short_description}
                    onChange={(e) => {
                      setNewProject({
                        ...newProject,
                        short_description: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    color="accent"
                    label="Description"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      setNewProject({
                        ...newProject,
                        description: e.target.value,
                      });
                    }}
                    value={newProject.description}
                    multiline
                    minRows={4}
                    maxRows={6}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    color="accent"
                    label="Technologies"
                    variant="outlined"
                    fullWidth
                    value={newProject.technologies?.join(", ") || ""}
                    onChange={(e) => {
                      setNewProject({
                        ...newProject,
                        technologies: e.target.value.split(", "),
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    color="accent"
                    label="Image"
                    variant="outlined"
                    fullWidth
                    value={newProject.image || ""}
                    onChange={(e) => {
                      setNewProject({ ...newProject, image: e.target.value });
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    color="accent"
                    label="Current Status"
                    variant="outlined"
                    fullWidth
                    select
                    value={newProject.current_status}
                    onChange={(e) => {
                      if (e.target.value === "Completed") {
                        setNewProject({
                          ...newProject,
                          progress: 100,
                          current_status: e.target.value,
                        });
                      } else if (e.target.value === "Not Started") {
                        setNewProject({
                          ...newProject,
                          progress: 0,
                          current_status: e.target.value,
                        });
                      } else {
                        setNewProject({
                          ...newProject,
                          current_status: e.target.value,
                        });
                      }
                    }}
                  >
                    <MenuItem value="Not Started">Not Started</MenuItem>
                    <MenuItem value="In Progress">In Progress</MenuItem>
                    <MenuItem value="Completed">Completed</MenuItem>
                    <MenuItem value="On Hold">On Hold</MenuItem>
                    <MenuItem value="Discarded">Discarded</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    color="accent"
                    label="Progress"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      setNewProject({
                        ...newProject,
                        progress: parseInt(e.target.value) || 0,
                      });
                    }}
                    value={newProject.progress || 0}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{
                            p: 1,
                          }}
                        >
                          <CircularProgress
                            variant="determinate"
                            value={newProject.progress || 0}
                            sx={{
                              color: "success.main",
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    color="accent"
                    label="Github Link"
                    variant="outlined"
                    fullWidth
                    value={newProject.github_link || ""}
                    onChange={(e) => {
                      setNewProject({
                        ...newProject,
                        github_link: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    color="accent"
                    label="Demo Link"
                    variant="outlined"
                    fullWidth
                    value={newProject.demo_link || ""}
                    onChange={(e) => {
                      setNewProject({
                        ...newProject,
                        demo_link: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: "backgroundColor.contrastText",
                      }}
                    >
                      Featured?
                    </Typography>
                    <Switch
                      color="accent"
                      checked={newProject.is_featured || false}
                      onChange={(e) => {
                        setNewProject({
                          ...newProject,
                          is_featured: e.target.checked,
                        });
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    color="accent"
                    label="Featured Image"
                    variant="outlined"
                    fullWidth
                    value={newProject.featured_image || ""}
                    onChange={(e) => {
                      setNewProject({
                        ...newProject,
                        featured_image: e.target.value,
                      });
                    }}
                    disabled={!newProject.is_featured}
                  />
                </Grid>
              </Grid>
            </Collapse>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                variant={isAdding ? "outlined" : "contained"}
                color={isAdding ? "error" : "primary"}
                size="small"
                startIcon={isAdding ? <Close /> : <Add />}
                onClick={() => {
                  setIsAdding(!isAdding);
                }}
              >
                {isAdding ? "Cancel" : "Add"}
              </Button>
              {isAdding && (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    handleSubmit();
                  }}
                  startIcon={<Add />}
                >
                  Submit
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            maxHeight: "100vh",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            gap: 1,
            p: 2,
            pl: 0,
            pt: "12rem",
          }}
        >
          {isSearching ? (
            <>
              {filteredData?.length > 0 ? (
                <>
                  {filteredData?.map((data, idx) => (
                    <Card data={data} key={`filter-data-${idx}`} />
                  ))}
                </>
              ) : (
                <Typography
                  variant="h4"
                  sx={{
                    color: "backgroundColor.contrastText",
                  }}
                >
                  No Data Found
                </Typography>
              )}
            </>
          ) : (
            <>
              {projects?.length > 0 ? (
                <>
                  {projects?.map((doc, idx) => (
                    <Card data={doc} key={`data-${idx}`} />
                  ))}
                </>
              ) : (
                <Typography
                  variant="h4"
                  sx={{
                    color: "backgroundColor.contrastText",
                  }}
                >
                  No Data Found
                </Typography>
              )}
            </>
          )}
        </Box>
      </Box>
    </NavLayout>
  );
};

export default Projects;
