import React, { useEffect } from "react";
import { Button, Box } from "@mui/material";
import { Google } from "@mui/icons-material";
import NavLayout from "../Layout/NavLayout";
import { signInWithGoogle } from "../Firebase/auth";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const user = window.localStorage.getItem("user");
    if (user) {
      navigate("/");
    }
  }, [navigate]);
  return (
    <NavLayout>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            py: 4,
            px: 8,
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
            backgroundColor: "accent.main",
          }}
        >
          <Button
            variant="contained"
            startIcon={<Google />}
            onClick={signInWithGoogle}
          >
            Continue with Google
          </Button>
        </Box>
      </Box>
    </NavLayout>
  );
};

export default Login;
