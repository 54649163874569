import { Env, Config } from "../Interface";

const getEnv = (): Env => ({
  node_env: process.env.NODE_ENV,
  firebase_api_key: process.env.REACT_APP_FIREBASE_API_KEY,
  firebase_messaging_sender_id:
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  firebase_app_id: process.env.REACT_APP_FIREBASE_APP_ID,
  app_version: process.env.REACT_APP_APP_VERSION,
});

const generateConfig = (env: Env): Config => {
  for (const [key, value] of Object.entries(env)) {
    if (value === undefined) {
      throw new Error(`Missing environment variable: ${key}`);
    }
  }

  return env as Config;
};

export default generateConfig(getEnv());
