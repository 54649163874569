import { auth } from ".";
import { GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";

const provider = new GoogleAuthProvider();

export const signInWithGoogle = () => {
  signInWithPopup(auth, provider)
    .then((result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential?.accessToken;
      const user = result.user;

      window.localStorage.setItem("token", token || "");
      window.localStorage.setItem("user", JSON.stringify(user));
      window.location.href = "/";
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      const email = error.email;
      const credential = GoogleAuthProvider.credentialFromError(error);
      window.alert(errorMessage);
      console.log(errorCode, errorMessage, email, credential);
    });
};

export const logOut = () => {
  signOut(auth)
    .then(() => {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("user");
      window.location.href = "/login";
    })
    .catch((error) => {
      console.log(error);
    });
};
