const sanitizeFirebaseData = (data: any): any => {
  let responseObject: {
    [key: string]: any;
  } = {};
  Object.entries(data).forEach(([key, value]) => {
    const sanitizedKey = key.replace(/\s+/g, "_").toLowerCase();
    responseObject[sanitizedKey] = value;
  });
  return responseObject;
};

export { sanitizeFirebaseData };
