import React, { useState, useEffect } from "react";
import NavLayout from "../Layout/NavLayout";
import {
  Box,
  Typography,
  TextField,
  Dialog,
  Button,
  IconButton,
  Grid,
  Switch,
  Chip,
  Tooltip,
} from "@mui/material";
import {
  getCollection,
  addDocument,
  updateDocument,
  deleteDocument,
} from "../Firebase/firestore";
import { updateDocumentDate } from "../Firebase/db";
import { IExperienceData, IBitsData } from "../Interface";
import { Add, Delete, Edit } from "@mui/icons-material";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

interface IExperienceDialog {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  existingExperience?: IExperienceData;
  isEdit?: boolean;
}
const ExperienceDialog = ({
  open,
  setOpen,
  existingExperience,
  isEdit,
}: IExperienceDialog) => {
  const [experience, setExperience] = useState<IExperienceData>({
    id: "",
    company: "",
    positions: [],
    description: "",
  });

  const handleClose = () => {
    setExperience({
      id: "",
      company: "",
      positions: [],
      description: "",
    });

    setOpen(false);
  };

  useEffect(() => {
    if (open && isEdit && existingExperience) {
      setExperience(existingExperience);
    }
  }, [open, isEdit, existingExperience]);

  const handleSave = async () => {
    console.log(isEdit);
    console.log(experience);
    if (isEdit) {
      await updateDocument("experience", experience.id, experience);
    } else {
      await addDocument("experience", experience);
    }
    await updateDocumentDate("experience");
    toast.success("Experience Saved!");
    handleClose();
    setInterval(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography variant="h6">
          {isEdit ? "Edit Experience" : "Add Experience"}
        </Typography>
        <TextField
          color="accent"
          sx={{ mt: 2 }}
          label="Company"
          variant="outlined"
          value={experience.company}
          onChange={(e) =>
            setExperience({ ...experience, company: e.target.value })
          }
        />
        <TextField
          color="accent"
          sx={{ mt: 2 }}
          multiline
          minRows={2}
          label="Description"
          variant="outlined"
          value={experience.description}
          onChange={(e) =>
            setExperience({ ...experience, description: e.target.value })
          }
        />
        <Button
          color="accent"
          variant="outlined"
          sx={{ mt: 2 }}
          onClick={() => {
            const newPositions = [
              ...experience.positions,
              {
                title: "",
                start_date: "",
                end_date: "",
                isCurrent: false,
              },
            ];
            setExperience({
              ...experience,
              positions: newPositions,
            });
          }}
        >
          Add Position
        </Button>
        {experience.positions.map((pos, index) => (
          <Grid container spacing={2} key={`position-${index}`}>
            <Grid item xs={4}>
              <TextField
                color="accent"
                fullWidth
                label="Title"
                variant="outlined"
                value={pos.title}
                onChange={(e) => {
                  const newPositions = [...experience.positions];
                  newPositions[index].title = e.target.value;
                  setExperience({
                    ...experience,
                    positions: newPositions,
                  });
                }}
              />
            </Grid>
            <Grid item xs={pos.isCurrent ? 4 : 3}>
              <TextField
                color="accent"
                fullWidth
                label="Start Date"
                variant="outlined"
                value={pos.start_date}
                onChange={(e) => {
                  const newPositions = [...experience.positions];
                  newPositions[index].start_date = e.target.value;
                  setExperience({
                    ...experience,
                    positions: newPositions,
                  });
                }}
              />
            </Grid>
            <Grid item xs={pos.isCurrent ? 4 : 2}>
              <Button
                variant="text"
                fullWidth
                color="accent"
                endIcon={<Switch checked={pos.isCurrent} color="accent" />}
                onClick={() => {
                  const newPositions = [...experience.positions];
                  newPositions[index].isCurrent = !pos.isCurrent;
                  setExperience({
                    ...experience,
                    positions: newPositions,
                  });
                }}
              >
                Current
              </Button>
            </Grid>
            {!pos.isCurrent && (
              <Grid item xs={3}>
                <TextField
                  color="accent"
                  fullWidth
                  label="End Date"
                  variant="outlined"
                  value={pos.end_date}
                  onChange={(e) => {
                    const newPositions = [...experience.positions];
                    newPositions[index].end_date = e.target.value;
                    setExperience({
                      ...experience,
                      positions: newPositions,
                    });
                  }}
                />
              </Grid>
            )}
          </Grid>
        ))}
        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            sx={{ ml: 2 }}
            variant="contained"
            color="accent"
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

interface IBitsDialog {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  existingBit?: IBitsData;
  isEdit?: boolean;
}

const BitsDialog = ({ open, setOpen, existingBit, isEdit }: IBitsDialog) => {
  const [bit, setBit] = useState<IBitsData>({
    id: "",
    title: "",
    description: "",
    link: "",
    link_text: "",
    image: "",
    color: "",
    text_color: "",
    color_dark: "",
    text_color_dark: "",
  });

  const handleClose = () => {
    setBit({
      id: "",
      title: "",
      description: "",
      link: "",
      link_text: "",
      image: "",
      color: "",
      color_dark: "",
    });

    setOpen(false);
  };

  useEffect(() => {
    if (open && isEdit && existingBit) {
      setBit(existingBit);
    }
  }, [open, isEdit, existingBit]);

  const handleSave = async () => {
    console.log(isEdit);
    console.log(bit);
    if (isEdit) {
      await updateDocument("bits", bit.id, bit);
    } else {
      await addDocument("bits", bit);
    }
    await updateDocumentDate("bits");
    toast.success("Bit Saved!");
    handleClose();
    setInterval(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography variant="h6">{isEdit ? "Edit Bit" : "Add Bit"}</Typography>
        <TextField
          color="accent"
          sx={{ mt: 2 }}
          label="Title"
          variant="outlined"
          value={bit.title}
          onChange={(e) => setBit({ ...bit, title: e.target.value })}
        />
        <TextField
          color="accent"
          sx={{ mt: 2 }}
          multiline
          minRows={2}
          label="Description"
          variant="outlined"
          value={bit.description}
          onChange={(e) => setBit({ ...bit, description: e.target.value })}
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              color="accent"
              fullWidth
              label="Link"
              variant="outlined"
              value={bit.link}
              onChange={(e) => setBit({ ...bit, link: e.target.value })}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              color="accent"
              fullWidth
              label="Link Text"
              variant="outlined"
              value={bit.link_text}
              onChange={(e) => setBit({ ...bit, link_text: e.target.value })}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              color="accent"
              fullWidth
              label="Color"
              variant="outlined"
              value={bit.color}
              onChange={(e) => setBit({ ...bit, color: e.target.value })}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              color="accent"
              fullWidth
              label="Text Color"
              variant="outlined"
              value={bit.text_color}
              onChange={(e) => setBit({ ...bit, text_color: e.target.value })}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              color="accent"
              fullWidth
              label="Color Dark"
              variant="outlined"
              value={bit.color_dark}
              onChange={(e) => setBit({ ...bit, color_dark: e.target.value })}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              color="accent"
              fullWidth
              label="Text Color Dark"
              variant="outlined"
              value={bit.text_color_dark}
              onChange={(e) =>
                setBit({ ...bit, text_color_dark: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              color="accent"
              fullWidth
              label="Image"
              variant="outlined"
              value={bit.image}
              onChange={(e) => setBit({ ...bit, image: e.target.value })}
            />
          </Grid>
        </Grid>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            sx={{ ml: 2 }}
            variant="contained"
            color="accent"
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

const About = () => {
  const [experiences, setExperiences] = useState<IExperienceData[]>([]);
  const [openExperienceDialog, setOpenExperienceDialog] = useState(false);
  const [editExperience, setEditExperience] = useState<IExperienceData>();
  const [isEdit, setIsEdit] = useState(false);

  const [bits, setBits] = useState<IBitsData[]>([]);
  const [openBitsDialog, setOpenBitsDialog] = useState(false);
  const [editBit, setEditBit] = useState<IBitsData>();
  const [isEditBit, setIsEditBit] = useState(false);

  const deleteExperience = async (id: string, idx: number) => {
    const newExperiences = [...experiences];
    newExperiences.splice(idx, 1);
    await deleteDocument("experience", id);
    await updateDocumentDate("experience");
    toast.success("Experience Deleted!");
    setExperiences(newExperiences);
  };

  const deleteBit = async (id: string, idx: number) => {
    const newBits = [...bits];
    newBits.splice(idx, 1);
    await deleteDocument("bits", id);
    await updateDocumentDate("bits");
    toast.success("Bit Deleted!");
    setBits(newBits);
  };

  useEffect(() => {
    getCollection("experience")
      .then((data) => {
        // console.log(data);
        setExperiences(data);
      })
      .catch((err) => {
        console.log(err);
      });

    getCollection("bits")
      .then((data) => {
        // console.log(data);
        setBits(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <NavLayout>
      <ExperienceDialog
        open={openExperienceDialog}
        setOpen={setOpenExperienceDialog}
        existingExperience={editExperience}
        isEdit={isEdit}
      />
      <BitsDialog
        open={openBitsDialog}
        setOpen={setOpenBitsDialog}
        existingBit={editBit}
        isEdit={isEditBit}
      />
      <Grid
        container
        spacing={2}
        sx={{
          color: "backgroundColor.contrastText",
          p: 2,
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h4" sx={{}}>
            About
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "backgroundColor.light",
              p: 2,
              borderRadius: 4,
            }}
          >
            <Typography variant="h5">Experience</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setOpenExperienceDialog(true);
                setIsEdit(false);
              }}
              startIcon={<Add />}
            >
              Add
            </Button>
          </Box>
        </Grid>
        {experiences?.length &&
          experiences.map((exp, idx) => (
            <Grid item xs={12} md={6} lg={4} key={exp.id}>
              <Box
                sx={{
                  p: 2,
                  backgroundColor: "backgroundColor.light",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  borderRadius: 3,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "accent.main",
                  }}
                >
                  {exp.company}
                </Typography>
                <Typography variant="body2">
                  {exp.description.length > 100
                    ? exp.description.substring(0, 100) + "..."
                    : exp.description}
                </Typography>
                <Typography variant="body2">Positions:</Typography>
                {exp.positions.map((pos) => (
                  <Typography variant="body2" key={pos.title}>
                    {pos.title} -{" "}
                    {new Date(pos.start_date).toLocaleDateString("en-US")} -{" "}
                    {pos.isCurrent
                      ? "Present"
                      : new Date(pos.end_date!).toLocaleDateString("en-US")}
                  </Typography>
                ))}
                <Box
                  sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}
                >
                  <IconButton
                    onClick={() => {
                      setOpenExperienceDialog(true);
                      setIsEdit(true);
                      setEditExperience(exp);
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      deleteExperience(exp.id, idx);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          ))}
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "backgroundColor.light",
              p: 2,
              borderRadius: 4,
            }}
          >
            <Typography variant="h5">Bits</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setOpenBitsDialog(true);
                setIsEditBit(false);
              }}
              startIcon={<Add />}
            >
              Add
            </Button>
          </Box>
        </Grid>
        {bits?.length &&
          bits.map((bit, idx) => (
            <Grid item xs={12} md={6} lg={4} key={bit.id}>
              <Box
                sx={{
                  p: 2,
                  backgroundColor: "backgroundColor.light",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  borderRadius: 3,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "accent.main",
                  }}
                >
                  {bit.title}
                </Typography>
                <Typography variant="body2">{bit.description}</Typography>
                <Grid container spacing={1}>
                  {bit.color?.length && (
                    <Grid item xs={6}>
                      <Chip
                        label={bit.color}
                        sx={{
                          backgroundColor: bit.color,
                          color: bit.text_color?.length
                            ? bit.text_color
                            : "backgroundColor.contrastText",
                          width: "100%",
                        }}
                      />
                    </Grid>
                  )}
                  {bit.color_dark?.length && (
                    <Grid item xs={6}>
                      <Chip
                        label={bit.color_dark}
                        sx={{
                          backgroundColor: bit.color_dark,
                          color: bit.text_color_dark?.length
                            ? bit.text_color_dark
                            : "backgroundColor.contrastText",
                          width: "100%",
                        }}
                      />
                    </Grid>
                  )}
                  {bit.link?.length && (
                    <Grid item xs={6}>
                      <Link
                        to={bit.link}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <Chip
                          label={bit.link_text || bit.link}
                          sx={{
                            backgroundColor: "primary.main",
                            color: "backgroundColor.contrastText",
                            width: "100%",
                          }}
                        />
                      </Link>
                    </Grid>
                  )}
                  {bit.image?.length && (
                    <Grid item xs={6}>
                      <Tooltip
                        title={
                          <img
                            src={bit.image}
                            alt={bit.title}
                            style={{ width: "100%", aspectRatio: "16/9" }}
                          />
                        }
                      >
                        <Chip
                          label="Image"
                          sx={{
                            backgroundColor: "primary.main",
                            color: "backgroundColor.contrastText",
                            width: "100%",
                          }}
                        />
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
                <Box
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <IconButton
                    onClick={() => {
                      setIsEditBit(true);
                      setEditBit(bit);
                      setOpenBitsDialog(true);
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      deleteBit(bit.id, idx);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          ))}
      </Grid>
    </NavLayout>
  );
};

export default About;
