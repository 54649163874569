import React, { FC, useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  useTheme,
  Button,
  Avatar,
} from "@mui/material";
import {
  AccountBox,
  Handshake,
  Engineering,
  AccountTree,
  Home,
  Circle,
  Logout,
} from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { logOut } from "../Firebase/auth";
const Nav: FC<{
  user: any;
  isAuthenticated: boolean;
}> = ({ user, isAuthenticated }) => {
  const [pages] = useState<
    {
      title: string;
      href: string;
      icon: React.ReactNode;
    }[]
  >([
    {
      title: "Home",
      href: "/",
      icon: (
        <Home
          sx={{
            color: "inherit",
            opacity: 0.8,
          }}
        />
      ),
    },
    {
      title: "Now",
      href: "/now",
      icon: (
        <Circle
          sx={{
            color: "inherit",
            opacity: 0.8,
          }}
        />
      ),
    },
    {
      title: "Resources",
      href: "/resources",
      icon: (
        <AccountTree
          sx={{
            color: "inherit",
            opacity: 0.8,
          }}
        />
      ),
    },
    {
      title: "Projects",
      href: "/projects",
      icon: (
        <Engineering
          sx={{
            color: "inherit",
            opacity: 0.8,
          }}
        />
      ),
    },

    {
      title: "About Me",
      href: "/about",
      icon: (
        <AccountBox
          sx={{
            color: "inherit",
            opacity: 0.8,
          }}
        />
      ),
    },
    {
      title: "Messages and Contact",
      href: "/messages",
      icon: (
        <Handshake
          sx={{
            color: "inherit",
            opacity: 0.8,
          }}
        />
      ),
    },
  ]);

  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: `${theme.palette.backgroundColor.light}`,
        border: "none",
        py: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Box
          sx={{
            p: 2,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            sx={{
              px: 2,
              py: 0.6,
              borderRadius: 90,
              backgroundColor: "backgroundColor.main",
              border: `1px solid ${theme.palette.accent.main}`,
              color: "accent.main",
              fontFamily: "monospace",
              textTransform: "none",
              boxShadow: `0px 4px 30px ${theme.palette.accent.main}`,
              "&:hover": {
                backgroundColor: "backgroundColor.main",
                border: `1px solid ${theme.palette.accent.main}`,

                color: "accent.main",
              },
              "&.Mui-disabled": {
                color: theme.palette.accent.main,
              },
            }}
          >
            Dash x Sid
          </Button>
        </Box>
        <List
          sx={{
            px: 2,
            "& .MuiListItemButton-root": {
              borderRadius: 30,
              my: 1,
              "&:hover": {
                backgroundColor: theme.palette.accent.light,
              },
            },
          }}
        >
          {pages.map((page, idx) => (
            <NavLink to={page.href} key={`page-${idx}`}>
              {({ isActive }) => (
                <ListItemButton
                  sx={{
                    backgroundColor: isActive
                      ? theme.palette.accent.main
                      : theme.palette.backgroundColor.light,
                    color: isActive
                      ? theme.palette.backgroundColor.main
                      : theme.palette.accent.main,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: "inherit",
                    }}
                  >
                    {page.icon}
                  </ListItemIcon>
                  <ListItemText primary={page.title} />
                </ListItemButton>
              )}
            </NavLink>
          ))}
        </List>
      </Box>
      <Box sx={{ p: 2 }}>
        {!isAuthenticated ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: theme.palette.backgroundColor.main,
              color: theme.palette.accent.main,
              borderRadius: 30,
              py: 2,
            }}
          >
            <Typography variant="body2">Login to continue</Typography>
          </Box>
        ) : (
          <>
            <Button
              disableFocusRipple
              variant="outlined"
              startIcon={<Logout />}
              fullWidth
              onClick={logOut}
              sx={{
                mb: 1,
                borderRadius: 30,
              }}
              color="error"
            >
              Logout
            </Button>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: theme.palette.backgroundColor.main,
                color: theme.palette.accent.main,
                gap: 1,
                px: 1,
                py: 1,
                borderRadius: 30,
              }}
            >
              <Avatar
                src={user.photoURL}
                sx={{
                  height: "100%",
                  aspectRatio: "1/1",
                }}
              />

              <Box
                sx={{ display: "flex", flexDirection: "column", width: "80%" }}
              >
                <Typography variant="body2" noWrap>
                  {user.displayName}
                </Typography>
                <Typography
                  variant="caption"
                  noWrap
                  sx={{
                    opacity: 0.8,
                    color: theme.palette.backgroundColor.contrastText,
                  }}
                >
                  {user.email}
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Nav;
