import React from "react";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Now from "./Pages/Now";
import Projects from "./Pages/Projects";
import About from "./Pages/About";
function App() {
  const theme = useTheme();
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/now",
      element: <Now />,
    },
    {
      path: "/projects",
      element: <Projects />,
    },
    {
      path: "/about",
      element: <About />,
    },
  ]);
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        maxWidth: "100%",
        maxHeight: "100%",
        overflow: "hidden",
        backgroundColor: theme.palette.backgroundColor.main,
      }}
    >
      <ToastContainer
        position={toast.POSITION.BOTTOM_CENTER}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
      />
      <RouterProvider router={router} />
    </Box>
  );
}

export default App;
