import React from "react";
import NavLayout from "../Layout/NavLayout";
const Home = () => {
  return (
    <NavLayout>
      <h1>Home</h1>
    </NavLayout>
  );
};

export default Home;
