import React, { FC, useState, useEffect } from "react";
import Nav from "../Components/Nav";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
const NavLayout: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<any>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  useEffect(() => {
    const authUser = window.localStorage.getItem("user");
    if (authUser) {
      console.log(JSON.parse(authUser));
      setUser(JSON.parse(authUser));
      setIsAuthenticated(true);
    } else {
      navigate("/login");
    }
  }, [navigate]);
  return (
    <Grid
      container
      spacing={2}
      sx={{
        height: "calc(100% + 16px)",
      }}
    >
      <Grid item xs={2.5} lg={2.5} md={3}>
        <Nav user={user} isAuthenticated={isAuthenticated} />
      </Grid>
      <Grid item xs={9.5} lg={9.5} md={9}>
        {children}
      </Grid>
    </Grid>
  );
};

export default NavLayout;
