import React, { useState, useEffect } from "react";
import NavLayout from "../Layout/NavLayout";
import { INowData } from "../Interface/data.interface";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
  useTheme,
  Collapse,
  Button,
  MenuItem,
  Grid,
  CircularProgress,
} from "@mui/material";
import {
  getCollection,
  addDocument,
  updateDocument,
  deleteDocument,
} from "../Firebase/firestore";
import { updateDocumentDate } from "../Firebase/db";
import { object, string, number } from "yup";
import { toast } from "react-toastify";
import {
  Add,
  Close,
  Delete,
  Done,
  Edit,
  Remove,
  Search,
} from "@mui/icons-material";
const Card: React.FC<{ data: Partial<INowData> }> = ({ data }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editData, setEditData] = useState<Partial<INowData>>({
    title: "",
    description: "",
    progress: 0,
    category: "",
    sub_category: "",
    link_text: "",
    link: "",
    image: "",
  });

  const handleEdit = () => {
    setIsEditing(true);
    setEditData(data);
  };
  const handleCancel = () => {
    setIsEditing(false);
    setEditData({
      title: "",
      description: "",
      progress: 0,
      category: "",
      sub_category: "",
      link_text: "",
      link: "",
      image: "",
    });
  };

  const handleUpdate = () => {
    const schema = object({
      title: string().required(),
      description: string().required(),
      progress: number(),
      category: string().required(),
      sub_category: string().required(),
      link_text: string(),
      link: string(),
      image: string(),
    });

    schema
      .validate(editData)
      .then((res) => {
        updateDocument("now", data.id!, res)
          .then((res) => {
            window.location.reload();
            toast.success("Updated Successfully");
            updateDocumentDate("now")
              .then((res) => {
                console.log(res);
              })
              .catch((err) => {
                console.log(err);
              });
            setIsEditing(false);
          })
          .catch((err) => {
            throw err;
          });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };

  const handleDelete = () => {
    deleteDocument("now", data.id!);
    toast.success("Deleted Successfully");
  };

  return (
    <Box
      sx={{
        width: "100%",
        py: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        p: 2,
        borderRadius: 2,
        color: "backgroundColor.contrastText",
        backgroundColor: "backgroundColor.light",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            color="accent"
            label="Title"
            variant="outlined"
            fullWidth
            value={isEditing ? editData.title : data.title}
            onChange={(e) => {
              setEditData({ ...editData, title: e.target.value });
            }}
            disabled={!isEditing}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            color="accent"
            label="Category"
            variant="outlined"
            select
            fullWidth
            value={isEditing ? editData.category : data.category}
            disabled={!isEditing}
            onChange={(e) => {
              setEditData({ ...editData, category: e.target.value });
            }}
          >
            <MenuItem value="Creating">Creating</MenuItem>
            <MenuItem value="Notes">Notes</MenuItem>
            <MenuItem value="Consuming">Consuming</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={3}>
          <TextField
            color="accent"
            label="Sub Category"
            variant="outlined"
            fullWidth
            value={isEditing ? editData.sub_category : data.sub_category}
            disabled={!isEditing}
            onChange={(e) => {
              setEditData({ ...editData, sub_category: e.target.value });
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 1,
          }}
        >
          {isEditing ? (
            <>
              <IconButton onClick={handleCancel}>
                <Close />
              </IconButton>
              <IconButton onClick={handleUpdate}>
                <Done />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton onClick={handleEdit}>
                <Edit />
              </IconButton>
              <IconButton onClick={handleDelete}>
                <Delete
                  sx={{
                    color: "error.main",
                  }}
                />
              </IconButton>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            color="accent"
            label="Description"
            variant="outlined"
            fullWidth
            onChange={(e) => {
              setEditData({ ...editData, description: e.target.value });
            }}
            value={isEditing ? editData.description : data.description}
            multiline
            minRows={4}
            disabled={!isEditing}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            color="accent"
            label="Progress"
            variant="outlined"
            fullWidth
            onChange={(e) => {
              setEditData({
                ...editData,
                progress: parseInt(e.target.value) || 0,
              });
            }}
            value={
              isEditing ? editData.progress : data.progress || "No Progress"
            }
            disabled={!isEditing}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{
                    p: 1,
                  }}
                >
                  <CircularProgress
                    variant="determinate"
                    value={isEditing ? editData.progress : data.progress || 0}
                    sx={{
                      color: "success.main",
                    }}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {isEditing && (
                    <>
                      <IconButton
                        onClick={() => {
                          setEditData({
                            ...editData,
                            progress: editData?.progress
                              ? editData.progress - 1
                              : 0,
                          });
                        }}
                      >
                        <Remove />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setEditData({
                            ...editData,
                            progress: editData?.progress
                              ? editData.progress + 1
                              : 0,
                          });
                        }}
                      >
                        <Add />
                      </IconButton>
                    </>
                  )}
                </>
              ),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            color="accent"
            label="Link Text"
            variant="outlined"
            fullWidth
            value={
              isEditing ? editData.link_text : data.link_text || "No Link Text"
            }
            disabled={!isEditing}
            onChange={(e) => {
              setEditData({ ...editData, link_text: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            color="accent"
            label="Link"
            variant="outlined"
            fullWidth
            value={isEditing ? editData.link : data.link || "No Link"}
            disabled={!isEditing}
            onChange={(e) => {
              setEditData({ ...editData, link: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            color="accent"
            label="Image"
            variant="outlined"
            fullWidth
            value={isEditing ? editData.image : data.image || "No Image"}
            disabled={!isEditing}
            onChange={(e) => {
              setEditData({ ...editData, image: e.target.value });
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
const Now = () => {
  const theme = useTheme();
  const [data, setData] = useState<Partial<INowData>[]>([]);
  const [newData, setNewData] = useState<Partial<INowData>>({
    title: "",
    description: "",
    progress: 0,
    category: "",
    sub_category: "",
    link_text: "",
    link: "",
    image: "",
  });
  const [isAdding, setIsAdding] = useState<boolean>(false);

  const [filteredData, setFilteredData] = useState<Partial<INowData>[]>([]);
  const [search, setSearch] = useState<string>("");
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const handleSubmit = () => {
    const schema = object({
      title: string().required(),
      description: string().required(),
      progress: number(),
      category: string().required(),
      sub_category: string().required(),
      link_text: string(),
      link: string(),
      image: string(),
    });

    schema
      .validate(newData)
      .then((res) => {
        addDocument("now", res);
        toast.success("Added Successfully");
        setIsAdding(false);
        setNewData({
          title: "",
          description: "",
          progress: 0,
          category: "",
          sub_category: "",
          link_text: "",
          link: "",
          image: "",
        });
        setData([...data, res]);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };

  const handleSearch = () => {
    if (search.length > 0) {
      setIsSearching(true);
      const filtered = data.filter((item) => {
        return item.title?.toLowerCase().includes(search.toLowerCase());
      });
      setFilteredData(filtered);
    } else {
      setIsSearching(false);
    }
  };

  useEffect(() => {
    getCollection("now")
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <NavLayout>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "calc(100% - 1rem)",
            height: isAdding ? "fit-content" : "11rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 2,
            p: 2,
            borderRadius: 2,
            backgroundColor: `${theme.palette.backgroundColor.light}dd`,
            backdropFilter: "blur(10px)",
            position: "absolute",
            top: "0.5rem",
            left: "0rem",
            zIndex: 99,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: "accent.main",
            }}
          >
            How's it going?
          </Typography>
          <TextField
            label="Search"
            variant="outlined"
            fullWidth
            value={search}
            sx={{
              "& input": {
                color: "accent.main",
              },
            }}
            color="accent"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSearch}>
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: isAdding ? 2 : 0,
            }}
          >
            <Collapse
              unmountOnExit
              in={isAdding}
              sx={{
                "& .MuiCollapse-wrapperInner": {
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  backgroundColor: "backgroundColor.light",
                  borderRadius: 2,
                  p: isAdding ? 1 : 0,
                },
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Title"
                    variant="outlined"
                    color="accent"
                    fullWidth
                    value={newData.title}
                    onChange={(e) => {
                      setNewData({ ...newData, title: e.target.value });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Category"
                    variant="outlined"
                    color="accent"
                    fullWidth
                    value={newData.category}
                    select
                    onChange={(e) => {
                      setNewData({ ...newData, category: e.target.value });
                    }}
                  >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="Creating">Creating</MenuItem>
                    <MenuItem value="Notes">Notes</MenuItem>
                    <MenuItem value="Consuming">Consuming</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Sub Category"
                    variant="outlined"
                    color="accent"
                    fullWidth
                    value={newData.sub_category}
                    onChange={(e) => {
                      setNewData({ ...newData, sub_category: e.target.value });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Description"
                    variant="outlined"
                    color="accent"
                    fullWidth
                    value={newData.description}
                    multiline
                    minRows={4}
                    onChange={(e) => {
                      setNewData({ ...newData, description: e.target.value });
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Progress"
                    variant="outlined"
                    color="accent"
                    fullWidth
                    value={newData.progress}
                    onChange={(e) => {
                      setNewData({
                        ...newData,
                        progress: parseInt(e.target.value) || 0,
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{
                            p: 1,
                          }}
                        >
                          <CircularProgress
                            variant="determinate"
                            value={newData.progress}
                            sx={{
                              color: "success.main",
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Link"
                    variant="outlined"
                    color="accent"
                    fullWidth
                    value={newData.link}
                    onChange={(e) => {
                      setNewData({ ...newData, link: e.target.value });
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Link Text"
                    variant="outlined"
                    color="accent"
                    fullWidth
                    value={newData.link_text}
                    onChange={(e) => {
                      setNewData({ ...newData, link_text: e.target.value });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Image"
                    variant="outlined"
                    color="accent"
                    fullWidth
                    value={newData.image}
                    onChange={(e) => {
                      setNewData({ ...newData, image: e.target.value });
                    }}
                  />
                </Grid>
              </Grid>
            </Collapse>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                variant={isAdding ? "outlined" : "contained"}
                color={isAdding ? "error" : "primary"}
                size="small"
                startIcon={isAdding ? <Close /> : <Add />}
                onClick={() => {
                  setIsAdding(!isAdding);
                }}
              >
                {isAdding ? "Cancel" : "Add"}
              </Button>
              {isAdding && (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    handleSubmit();
                  }}
                  startIcon={<Add />}
                >
                  Submit
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            maxHeight: "100vh",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            gap: 1,
            p: 2,
            pl: 0,
            pt: "12rem",
          }}
        >
          {isSearching ? (
            <>
              {filteredData?.length > 0 ? (
                <>
                  {filteredData?.map((data, idx) => (
                    <Card data={data} key={`filter-data-${idx}`} />
                  ))}
                </>
              ) : (
                <Typography
                  variant="h4"
                  sx={{
                    color: "backgroundColor.contrastText",
                  }}
                >
                  No Data Found
                </Typography>
              )}
            </>
          ) : (
            <>
              {data?.length > 0 ? (
                <>
                  {data?.map((doc, idx) => (
                    <Card data={doc} key={`data-${idx}`} />
                  ))}
                </>
              ) : (
                <Typography
                  variant="h4"
                  sx={{
                    color: "backgroundColor.contrastText",
                  }}
                >
                  No Data Found
                </Typography>
              )}
            </>
          )}
        </Box>
      </Box>
    </NavLayout>
  );
};

export default Now;
